import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncCategories } from "../../Redux/features/CommonSlice";

function SelectService({ setGoSteps, onServiceSelect, mode }) {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  const handleNextStep = () => {
    onServiceSelect(selectedItem);
    setGoSteps(2);
  };

  useEffect(() => {
    dispatch(getAsyncCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);
  console.log(categories)

  return (
    <>
      <div className="row justify-content-center mx-1 ">
        <div className=" col-md-9 col-lg-10 col-xxl-7 col-12 text-center custom-border waitlist-box">
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(0)}
                className=" gj-cursor-pointer back-btn fs-1 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center">
              <h2 className=" display-6 fw-bold ">Services</h2>
            </div>
            <div className="col-2 text-start"></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-11 overflow-auto mobile-height">
              <div className="row justify-content-center">
                {categories?.map((c, index) => (
                  <div className="col-lg-10 col-11 mb-2" key={index}>
                    <div className="row category-border py-3 my-3 px-2">
                      <div className="col-12 text-start my-1">
                        <p className="fs-2 ms-lg-5 ms-0 fw-bold mb-0 category-title">
                          {c.name}
                        </p>
                      </div>
                      {c?.services?.map((i) => (
                        <div
                          key={i.id}
                          className={`form-check mx-auto category-border col-lg-11 col-12 px-0 my-3 ${selectedItem === i.id ? "service-bg" : ""
                            }`}
                        >
                          <label
                            onClick={() => handleItemClick(i.id)}
                            className="row mx-0"
                          >
                            <div className="col-12 py-2 text-start">
                              <p className="fs-5 pb-2 fw-bold m-0 user-select-none">
                                {i.name}
                              </p>
                              <p className="mb-0 user-select-none">
                                {i.description}
                              </p>
                              <p className="m-0 user-select-none">
                                <span className="me-3">
                                  <BiTimeFive></BiTimeFive>{" "}
                                  {i.durationInMinutes} minutes{" "}
                                </span>
                                <span>
                                  {
                                    mode === "reservation" ? <span>$ {i.reservationPriceInDollar}</span> : <span>$ {i.waitlistPriceInDollar}</span>
                                  }
                                </span>
                              </p>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-8 col-lg-6 mx-auto mt-5">
            <button
              onClick={handleNextStep}
              className="btn-main bg-primary w-100"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectService;
