import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Services from "../pages/ServicesPage";
import Contact from "../pages/Contact";
import Gallery from "../pages/Gallery";
import WaitList from "../pages/WaitList";
import Confirmation from "../pages/Confirmation";
import Preloader from "../components/PreLoader";

const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Home></Home>
        <Preloader />
      </>
    ),
  },
  {
    path: "/about",
    element: <About></About>,
  },
  {
    path: "/services",
    element: <Services></Services>,
  },
  {
    path: "/contact",
    element: <Contact></Contact>,
  },
  {
    path: "/gallery",
    element: <Gallery></Gallery>,
  },
  {
    path: "/waitlist",
    element: <WaitList></WaitList>,
  },
  {
    path: "/confirmation",
    element: <Confirmation></Confirmation>,
  },
  {
    path: "/confirmation/:id",
    element: <Confirmation></Confirmation>,
  },
  {
    path: "/reservation-confirmation/:id",
    element: <Confirmation></Confirmation>,
  },
]);

export default Router;
