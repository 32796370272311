import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncSchedule } from "../Redux/features/CommonSlice";
import { getAsyncContact } from "../Redux/features/ContactSlice";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const Schedule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncSchedule());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAsyncContact());
  }, [dispatch]);

  const getSchedule = useSelector((state) => state.Common.schedule);
  console.log("getSchedule", getSchedule);
  const getContact = useSelector((state) => state.Contact.contacts);


  // Aggregate working hours by day
  const aggregatedWorkingHours = (getSchedule || []).reduce(
    (acc, currentValue) => {
      const { dayOfWeek, barberTimeSlots } = currentValue;
      acc[dayOfWeek] = acc[dayOfWeek] || [];
      acc[dayOfWeek].push(...barberTimeSlots);
      return acc;
    },
    {}
  );

  // Convert to 12 hour
  function convertTo12Hour(time) {
    let date = new Date();
    date.setHours(time.split(":")[0]);
    date.setMinutes(time.split(":")[1]);
    date.setSeconds(time.split(":")[2]);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }



  return (
    <div>
      <section className="jarallax no-top">
        <div className="de-gradient-edge-top"></div>
        <img src="/img/background/1.jpg" className="jarallax-img" alt="" />
        <div className="container relative z1000">
          <div className="row gx-5">
            <div
              className="col-lg-6 text-center my-3 d-flex flex-column"
              data-jarallax-element="-50"
            >
              <div className="d-sch-table flex-grow-1 waitlist-box">
                <h2 className="">We are Open</h2>
                  {daysOfWeek.map((day) => {
                    const timeSlots = aggregatedWorkingHours[day];
                    return (
                      <div className="row">
                        <div className="col-4 col-md-6">
                          <p>{day}</p>
                        </div>
                        <div className="col-8 col-md-6">
                          {timeSlots
                            ? timeSlots.map((slot, index) => (
                              <p>
                                {`${convertTo12Hour(
                                  slot.startTime
                                )} - ${convertTo12Hour(slot.endTime)}`}
                              </p>
                            ))
                            : "Closed"}
                        </div>
                      </div>
                    );
                  })}
                  <div className="d-deco"></div>
              </div>
            </div>
            <div
              className="col-lg-6 text-center my-3 d-flex flex-column"
              data-jarallax-element="-100"
            >
              <div className="d-sch-table flex-grow-1 waitlist-box">
                <h2 className="">Location</h2>
                <div className="d-col">
                  <div className="d-title">Address</div>
                  <div className="d-content id-color">
                    {getContact?.address}
                  </div>
                </div>
                <div className="de-separator"></div>
                <div className="d-col">
                  <div className="d-title">Phone</div>
                  <div className="d-content id-color">{getContact?.phone}</div>
                </div>

                <div className="d-deco"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="de-gradient-edge-bottom"></div>
      </section>
    </div>
  );
};

export default Schedule;
