import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { useSelector } from "react-redux";

function Footer() {
  const socialLink = useSelector((state) => state.Common.socialLinks);

  const validSocialLinks = Object?.entries(socialLink).reduce(
    (acc, [key, value]) => {
      // Check if the URL is more than just the base URL of the platform
      if (
        value &&
        value !== `https://www.${key}.com` &&
        value !== `https://www.${key}.com/`
      ) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  return (
    <footer className="footer-bg">
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-4 text-center">
            <div className="social-icons big">
              {validSocialLinks?.facebook && (
                <a
                  href={validSocialLinks?.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className="social-box py-2"
                >
                  <FaFacebookF />
                </a>
              )}
              {validSocialLinks?.instagram && (
                <a
                  href={validSocialLinks?.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className="social-box py-2"
                >
                  <FaInstagram />
                </a>
              )}
              {validSocialLinks?.tiktok && (
                <a
                  href={validSocialLinks?.tiktok}
                  target="_blank"
                  rel="noreferrer"
                  className="social-box py-2"
                >
                  <FaTiktok />
                </a>
              )}
            </div>
          </div>
          <div className="col-lg-4 text-lg-center text-center">
            <img src="/img/ABSS.png" style={{ height: "70px" }} alt="" />
          </div>
          <div className="col-lg-4 text-lg-end text-center pt-2">
            Copyright 2023 -{" "}
            <a
              style={{ color: "var(--primary-color)" }}
              href="https://abss.tech"
              target="blank"
            >
              {" "}
              ABSS
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
