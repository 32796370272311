import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './router/Router';
import { RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './Redux/store';
import amplifyConfig from './amplifyconfiguration.json';
import { Amplify } from 'aws-amplify';


Amplify.configure({
  API: {
    GraphQL: {
      endpoint:
        process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT ||
        "https://xnuogii7vzaurlfmigqbntb724.appsync-api.eu-central-1.amazonaws.com/graphql",
      region: process.env.REACT_APP_APPSYNC_REGION || "eu-central-1",
      defaultAuthMode:
        process.env.REACT_APP_APPSYNC_AUTH_MODE || "API_KEY",
      apiKey:
        process.env.REACT_APP_APPSYNC_API_KEY ||
        "da2-36ikssg5zfeodjmyqojukeglvu",
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <RouterProvider router={Router}></RouterProvider>
</Provider>
);

