import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import Swal from "sweetalert2";

const initialState = {
  mapLink: {},
  state: "idle",
  error: null,
};

// Get map link
export const getAsyncMapLink = createAsyncThunk("mapLink/mapLink", async () => {
  try {
    const res = await axiosInstance.get("/barbers/map-links");
    // console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
  }
});

const MapLinkSlice = createSlice({
  name: "mapLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsyncMapLink.pending, (state) => {
        state.state = "loading";
      })
      .addCase(getAsyncMapLink.fulfilled, (state, action) => {
        state.state = "success";
        state.mapLink = action.payload;
      })
      .addCase(getAsyncMapLink.rejected, (state, action) => {
        state.state = "failed";
        state.error = action.error.message;
      });
  },
});

export default MapLinkSlice.reducer;
