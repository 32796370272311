import { useEffect } from "react";
import { jarallax } from "jarallax";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Services from "../components/Services";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncAboutDetails } from "../Redux/features/AboutSlice";
import OurTeam from "../components/OurTeam";
import HeaderImage from "../components/HeaderImage";

function About() {
  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAsyncAboutDetails());
  }, [dispatch]);

  const aboutDetails = useSelector((state) => state.About.about);
  
  return (
    <div id="wrapper">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <HeaderImage image={aboutDetails?.url} title="About Us" />
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="custom-border col-12">
                <h3 className="text-center fw-bold fs-2">
                  {aboutDetails?.title}
                </h3>
                <div className="de-separator"></div>

                <p className="fs-5 text-center">{aboutDetails?.description}</p>
                <div className="double"></div>
              </div>
            </div>
          </div>
        </section>

        <Services></Services>

        <OurTeam></OurTeam>
      </div>
      <Footer />
    </div>
  );
}

export default About;
