import { configureStore } from "@reduxjs/toolkit";
import ContactSlice from "./features/ContactSlice";
import HomeSlice from "./features/HomeSlice";
import scheduleSlice from "./features/ScheduleSlice";
import AboutSlice from "./features/AboutSlice";
import commonSlice from "./features/CommonSlice";
import GallerySlice from "./features/GallerySlice";
import WaitlistSlice from "./features/WaitlistSlice";
import ReservationSlice from "./features/ReservationSlice";
import MapLinkSlice from "./features/MapLinkSlice";
import shopStatusSlice from "./features/shopStatusSlice";

const store = configureStore({
  reducer: {
    About: AboutSlice,
    Common: commonSlice,
    Contact: ContactSlice,
    Gallery: GallerySlice,
    Home: HomeSlice,
    Schedule: scheduleSlice,
    Waitlist: WaitlistSlice,
    Reservation: ReservationSlice,
    MapLink: MapLinkSlice,
    ShopStatus: shopStatusSlice,
  },
});

export default store;
