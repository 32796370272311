import React from 'react'

const HeaderImage = ({ image, title }) => {
  return (
    <section id="subheader" className='jarallax'>
      <img src={image} className="jarallax-img opacity-50" alt="" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 text-center">
            <h1>{title}</h1>
            <div className="de-separator"></div>
          </div>
        </div>
      </div>
      <div className="de-gradient-edge-bottom"></div>
    </section>
  )
}

export default HeaderImage