import { useEffect } from "react";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetHome } from "../Redux/features/HomeSlice";
import { getAsyncFavoriteImages } from "../Redux/features/GallerySlice";

const Header = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncGetHome());
    dispatch(getAsyncFavoriteImages());
  }, [dispatch]);

  // home data is here
  const homeData = useSelector((state) => state.Home.home);
  // favorite images to show in marquee
  const imageForMarquee = useSelector((state) => state.Gallery.favoriteImage);

  return (
    <div className="position-relative" style={{ backgroundColor: "#101010" }}>
      <img
        style={{
          height: "100vh",
          width: "100%",
          objectFit: "cover",
          objectPosition: "center",
          opacity: "0.5",
        }}
        src={homeData?.url}
        alt="home"
      />
      <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="">
          <div className="">
            <div className="wm">
              <Marquee
                key={homeData?.id}
                className="images-marquee-size"
                direction="right"
                speed={150}
              >
                {imageForMarquee?.map((image) => (
                  <img src={image.url} alt="" />
                ))}
              </Marquee>
            </div>
            <div className="v-center px-4">
              <div className="container-fluid z1000">
                <div className="row justify-content-center">
                  <div className="spacer-double"></div>
                  <div className="spacer-double"></div>
                  <div className="col-12 col-md-7 col-lg-12 text-wrap text-center">
                    <h1 className="text-center"> {homeData?.title}</h1>
                  </div>
                  <div className="col-12 px-3 col-md-7 col-lg-12 text-wrap text-center">
                    <p className="lead">{homeData?.subTitle}</p>
                  </div>
                </div>
                <div className="text-center">
                  <a href="#waitlist" className="btn-main bg-primary">
                    Book Now
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
