import { useEffect, useState } from "react";
import { jarallax } from "jarallax";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncAllImages, getAsyncHeadingImages } from "../Redux/features/GallerySlice";
import HeaderImage from "../components/HeaderImage";

function Gallery() {
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    dispatch(getAsyncHeadingImages());
    dispatch(getAsyncAllImages());
  }, [dispatch]);

  const headingImage = useSelector((state) => state.Gallery.headingImage);
  const allImage = useSelector((state) => state.Gallery.allImage);
  const status = useSelector((state) => state.Gallery.allImageStatus);

  return (
    <div id="wrapper">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <HeaderImage image={headingImage?.url} title="Gallery" />

        <section className="no-top no-bottom" aria-label="section">
          <div className="container-fluid px-4">
            <div id="gallery" className="row g-3 py-5">
              {status === "loading" ? (
                <>
                  <div style={{height: "100vh"}} className="d-flex mt-5 justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  {allImage?.map((image, index) => (
                    <div key={index} className="col-lg-3 col-md-6 item">
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="de-image-hover rounded"
                        onClick={() => openModal(image.url)}
                      >
                        <span className="dih-overlay"></span>
                        <img
                          src={image.url}
                          className="lazy img-fluid gallery-img"
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />

      {/* modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onClick={closeModal}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-body">
              {selectedImage && (
                <img
                  className="justify-content-center align-content-center "
                  style={{ objectFit: "cover",objectPosition: "center", width: "100%", height: "70vh" }}
                  src={selectedImage}
                  alt="Selected"
                />
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* modal end */}
    </div>
  );
}

export default Gallery;