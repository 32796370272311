import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncShopStatus } from "../../Redux/features/shopStatusSlice";

const WaitList = ({ handleButtonClick }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncShopStatus());
  }, [dispatch]);

  const shopStatus = useSelector((state) => state.ShopStatus.openStatus);

  return (
    <>
      <div className="row justify-content-center mx-1 ">
      <div className="de-gradient-edge-top"></div>
        <div className=" col-lg-8 col-md-10 col-12 py-5 mb-5 custom-border text-center waitlist-box">
          <h2 className="pt-1 display-5 mb-2"> Welcome to our Barber shop </h2>
          {/* <p>2 People Waiting</p> */}
          <div className="row justify-content-center">
            <div className="col-10 col-lg-6 mb-4">
              <button
                onClick={() => handleButtonClick("waitlist")}
                className={`btn-main w-100 bg-primary ${
                  shopStatus?.isOpen ? "" : "opacity-50"
                }`}
                disabled={!shopStatus?.isOpen}
              >
                Join WaitList
              </button>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-lg-6">
              <button
                onClick={() => handleButtonClick("reservation")}
                className="btn-main bg-primary w-100"
              >
                Reservation
              </button>
            </div>
          </div>
        </div>
        <div className="de-gradient-edge-bottom"></div>
      </div>
    </>
  );
};

export default WaitList;
