import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "https://xyagifkbn9.execute-api.eu-central-1.amazonaws.com/live",
  headers: {
    barberId: process.env.REACT_APP_BARBER_ID || "c395003b-42c3-48a4-ba8e-af1cebc623e2",
  },
});

export default axiosInstance;
