import { useEffect, useState } from "react";
import { jarallax } from "jarallax";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import {
  getAsyncContact,
  postAsyncContact,
} from "../Redux/features/ContactSlice";
import { useDispatch, useSelector } from "react-redux";
import { ContactSchema } from "../ValidationSchema";
import Swal from "sweetalert2";
import { getAsyncMapLink } from "../Redux/features/MapLinkSlice";
import HeaderImage from "../components/HeaderImage";

function Contact() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const phonePrefix = "+961";

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name,
      phone: phonePrefix + phone,
      subject,
      message,
    };
    console.log(data);
    try {
      await ContactSchema.validate(data);
      await dispatch(postAsyncContact(data));
      Swal.fire("Send!", "Your Message has been send.", "success");
      setName("");
      setPhone("");
      setSubject("");
      setMessage("");
    } catch (error) {
      Swal.fire("Validation Error", error.message, "error");
    }
  };

  // Validate field individually
  const validateField = async (name, value) => {
    try {
      // Validate the field only
      await ContactSchema.validateAt(name, { [name]: value });
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (error) {
      // Set error message if validation fails
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  // Handle field change
  const handleFieldChange = (name, value) => {
    // Set the touched state
    setTouched((prev) => ({ ...prev, [name]: true }));
    // Validate the field
    validateField(name, value);
  };

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  useEffect(() => {
    dispatch(getAsyncContact());
    dispatch(getAsyncMapLink());
  }, [dispatch]);
  const contact = useSelector((state) => state.Contact.contacts);
  const contactStatus = useSelector((state) => state.Contact.contactStatus);
  const mapLink = useSelector((state) => state.MapLink.mapLink);

  console.log("mapLink", mapLink);

  return (
    <div id="wrapper">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <HeaderImage image={contact?.url} title="Contact Us" />

        <section className="jarallax">
          <div className="de-gradient-edge-top"></div>
          <img src="/img/background/1.jpg" className="jarallax-img" alt="" />
          <div className="container pt-5 relative z1000">
            <div
              id="map"
              className="overflow-hidden"
              style={{
                height: "480px",
                marginBottom: "70px",
                position: "relative",
              }}
            >
              <iframe
                src={mapLink?.ifremLink}
                style={{ width: "100%", height: "500px", border: "none" }}
                title="map"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="de-gradient-edge-bottom"></div>
        </section>
        <section id="section-content" className="no-top" aria-label="section">
          <div className="container">
            <div className="row">
              <div className="col-6 text-center">
                <FaLocationDot className="de-icon gradient de-hover-scale text-light mb20" />
                <p className="lead no-bottom">Our Address</p>
                <h4 className="s2">{contact?.address}</h4>
              </div>
              <div className="col-6 text-center">
                <FaPhone className="de-icon gradient de-hover-scale text-light mb20" />
                <p className="lead no-bottom">Phone Number</p>
                <h4 className="s2">{contact?.phone}</h4>
              </div>
            </div>
          </div>
        </section>
        <section className="no-top jarallax">
          <div className="de-gradient-edge-top"></div>
          <img src="img/background/1.jpg" className="jarallax-img" alt="" />
          <div className="container position-relative z1000 ">
            <div className="row gx-5 ">
              <div className="col-lg-6 offset-lg-3 pt-4 ">
                <div className="d-sch-table waitlist-box">
                  <h2 className="text-center">Message Us</h2>
                  <div className="de-separator"></div>
                  <p className="lead text-center">
                    If you got any questions, please do not hestitae to send us
                    a message.
                  </p>
                  <form
                    onSubmit={handleFormSubmit}
                    name="contactForm"
                    id="contact_form"
                    className="form-border position-relative z1000"
                    method="post"
                    action="#"
                  >
                    <div className="row">
                      <div className="col-lg-12 mb10">
                        <div className="field-set">
                          <input
                            type="text"
                            name="Name"
                            id="name"
                            value={name}
                            className="form-control"
                            placeholder="Your Name"
                            onChange={(e) => {
                              setName(e.target.value);
                              handleFieldChange("name", e.target.value);
                            }}
                            onBlur={() => handleFieldChange("name", name)}
                          />
                          {touched.name && errors.name && (
                            <p
                              className="lead fs-6 mb-0"
                              style={{ color: "red" }}
                            >
                              {errors.name}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 mb10">
                        <div className="field-set">
                          <div className="input-group field-set">
                            <span className="input-group-text transperant-bg">
                              {phonePrefix}
                            </span>
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              value={phone}
                              className="form-control"
                              placeholder="Your phone"
                              onChange={(e) => {
                                setPhone(e.target.value);
                                handleFieldChange("phone", e.target.value);
                              }}
                              onBlur={() => handleFieldChange("phone", phone)}
                            />
                          </div>
                          {touched.phone && errors.phone && (
                            <p
                              className="lead fs-6 mb-0"
                              style={{ color: "red" }}
                            >
                              {errors.phone}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 mb10">
                        <div className="field-set">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            value={subject}
                            className="form-control"
                            placeholder="Your subject"
                            onChange={(e) => {
                              setSubject(e.target.value);
                              handleFieldChange("subject", e.target.value);
                            }}
                            onBlur={() => handleFieldChange("subject", subject)}
                          />
                          {touched.subject && errors.subject && (
                            <p
                              className="lead fs-6 mb-0"
                              style={{ color: "red" }}
                            >
                              {errors.subject}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="field-set mb20">
                      <textarea
                        name="message"
                        id="message"
                        value={message}
                        className="form-control"
                        placeholder="Your Message"
                        onChange={(e) => {
                          setMessage(e.target.value);
                          handleFieldChange("message", e.target.value);
                        }}
                        onBlur={() => handleFieldChange("message", message)}
                      ></textarea>
                      {touched.message && errors.message && (
                        <p
                          className="lead fs-6 mb-0 mb-0"
                          style={{ color: "red" }}
                        >
                          {errors.message}
                        </p>
                      )}
                    </div>
                    <div id="submit" className="my-4">
                      <input
                        type="submit"
                        id="send_message"
                        value={
                          contactStatus === "loading"
                            ? "Sending..."
                            : "Send Message"
                        }
                        className="btn-main bg-primary"
                      />
                    </div>
                  </form>
                  <div className="d-deco"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="de-gradient-edge-bottom"></div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
