import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { jarallax } from "jarallax";
import { RxCrossCircled } from "react-icons/rx";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdDirections } from "react-icons/md";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loading from "../components/Loading";
import { IoMenu } from "react-icons/io5";



import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelAsyncWaitlist,
  getOwnWaitlist,
} from "../Redux/features/WaitlistSlice";
import { getAsyncMapLink } from "../Redux/features/MapLinkSlice";
import {
  cancelAsyncReservation,
  getOwnReservation,
} from "../Redux/features/ReservationSlice";

function WaitList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [reservationPath, setReservationPath] = useState(false);
  const params = useParams().id;

  const status = useSelector((state) => state.Waitlist.status);

  useEffect(() => {
    dispatch(getOwnWaitlist(params));
    dispatch(getAsyncMapLink());
  }, [dispatch, params]);
  const singleWaitlist = useSelector((state) => state.Waitlist.singleWaitlist);
  const mapLink = useSelector((state) => state.MapLink.mapLink);

  // check path name
  useEffect(() => {
    setReservationPath(
      location.pathname.includes("/reservation-confirmation/")
    );
  }, [location.pathname]);

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, [singleWaitlist.length]);

  useEffect(() => {
    dispatch(getOwnReservation(params));
  }, [dispatch, params]);

  const ownReservation = useSelector(
    (state) => state.Reservation.singleReservation
  );

  console.log("ownReservation", ownReservation);

  const handleCancelReservation = (cancelationId) => {
    // Display confirmation modal
    if (!reservationPath) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure with these data?",
        icon: "warning",
        customClass: {
          title: "dark-text",
          content: "dark-text",
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((willCancel) => {
        if (willCancel) {
          dispatch(cancelAsyncWaitlist(cancelationId));
          navigate("/");
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure with these data?",
        icon: "warning",
        customClass: {
          title: "dark-text",
          content: "dark-text",
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((willCancel) => {
        if (willCancel) {
          dispatch(cancelAsyncReservation(cancelationId));
          navigate("/");
        }
      });
    }
  };

  return (
    <>
      <Header></Header>
      <div id="wrapper">
        <div className="no-bottom no-top" id="content">
          <div id="top"></div>

          <section id="subheader" className="jarallax">
            <img src="/img/background/2.jpg" className="jarallax-img" alt="" />
            <div className="container">
              <div className="row">
                <div className="col-lg-6 pt-5 mt-5 offset-lg-3 text-center">
                  <h2>Confirmation</h2>
                  <div className="de-separator"></div>
                </div>
              </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
          </section>

          <section id="wait" className="jarallax">
            <div className="de-gradient-edge-top"></div>
            <img src="/img/background/1.jpg" className="jarallax-img" alt="" />
            <div className="container relative z1000">
              <div className="row  justify-content-center">
                <div className=" col-md-10 bg-dark col-lg-9 col-xxl-6 col-12 custom-border">
                  <div className="row">
                    <div className="col-12">
                      <p className="text-center mb-0">BarberShop</p>
                      <div className="text-center py-3">
                        <IoMdCheckmarkCircleOutline
                          className="display-1"
                          style={{ color: "var(--primary-color)" }}
                        />
                      </div>
                      <h2 className="text-center mb-0">
                        Thanks for{" "}
                        {reservationPath ? "reservation!" : "waiting!"}
                      </h2>
                      {!reservationPath && (
                        <p className="text-center mb-0 fw-semibold">
                          Stay on this page to get notified when it's your turn.
                        </p>
                      )}
                    </div>
                  </div>
                  {status === "loading" ? (
                    <Loading />
                  ) : (
                    <>
                      {" "}
                      {!reservationPath ? (
                        <>
                          <div className="row justify-content-center text-center mt-4">
                            <div className="col-6">
                              <p className="mb-0">Place in line</p>
                              <p>{singleWaitlist[0]?.serialNumber}</p>
                            </div>
                            <div className="col-6">
                              <p className="mb-0">Estimate wait</p>
                              <p>{singleWaitlist[0]?.waitingTime} min</p>
                            </div>
                            <hr
                              style={{
                                backgroundColor: "var(--primary-color)",
                              }}
                            />
                          </div>
                          <div className="row pb-4 justify-content-center text-center">
                            {singleWaitlist?.map((item) => (
                              <>
                                <div className="col-lg-3 pb-3 col-6">
                                  <p className="mb-0 ">Name</p>
                                  <p className="mb-0 fw-bolder pb-1">
                                    {" "}
                                    {item?.name}
                                  </p>
                                </div>
                                <div className="col-lg-3 pb-3  col-6">
                                  <p className="mb-0 ">Phone</p>
                                  <p className="mb-0 fw-bolder pb-1">
                                    {item?.phone}
                                  </p>
                                </div>
                                <div className="col-lg-3 pb-3  col-6">
                                  <p className="mb-0 ">Staff</p>
                                  <p className="mb-0 fw-bolder pb-1">
                                    {item?.employeeName}
                                  </p>
                                </div>
                                <div className="col-lg-3 pb-3  col-6">
                                  <p className="mb-0 ">Services</p>
                                  <p className="mb-0 fw-bolder">
                                    {" "}
                                    {item?.serviceName}
                                  </p>
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row pb-4 pt-4 justify-content-center text-center">
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Name</p>
                              <p className="mb-0 fw-bolder pb-1">
                                {" "}
                                {ownReservation?.name}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Phone</p>
                              <p className="mb-0 fw-bolder pb-1">
                                {ownReservation?.customer?.phone}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Staff</p>
                              <p className="mb-0 fw-bolder pb-1">
                                {ownReservation?.employee?.name}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Service</p>
                              <p className="mb-0 fw-bolder">
                                {" "}
                                {ownReservation?.service?.name}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Date</p>
                              <p className="mb-0 fw-bolder pb-1">
                                {ownReservation?.reservationDate}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Time</p>
                              <p className="mb-0 fw-bolder">
                                {" "}
                                {ownReservation?.reservationTime}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Price</p>
                              <p className="mb-0 fw-bolder pb-1">
                                ${" "}
                                {
                                  ownReservation?.service
                                    ?.reservationPriceInDollar
                                }
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Time</p>
                              <p className="mb-0 fw-bolder">

                                {ownReservation?.service?.durationInMinutes} min
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      <hr
                        className="my-4"
                        style={{ backgroundColor: "var(--primary-color)" }}
                      />
                      <div className="row text-center justify-content-center align-items-center">
                        {
                          !reservationPath && (
                            <div className="col-4">
                              <Link
                                to="/waitlist"
                                className="my-3 fw-bolder text-white"
                              >
                                <IoMenu />
                                View Waitlist
                              </Link>
                            </div>
                          )
                        }

                        <div className="col-4">
                          <a
                            href={mapLink?.mapLink}
                            className="my-3 fw-bolder text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MdDirections className="fs-5" /> Get Directions
                          </a>
                        </div>
                        <div className="col-4">
                          <button
                            className="bg-transparent border-0 mb-0 fw-bolder text-white"
                            onClick={() => handleCancelReservation(params)}
                          >
                            <RxCrossCircled className="fs-5" />{" "}
                            {!reservationPath
                              ? "Cancel Waitlist"
                              : "Cancel Reservation"}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WaitList;
