import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const fetchAsyncShopStatus = createAsyncThunk(
  "shopStatus/getShopStatus",
  async () => {
    try {
      const res = await axiosInstance.get("/barbers/shop-open-status");
      return res.data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

const initialState = {
  openStatus: {},
  status: "idle",
  error: null,
};

const shopStatusSlice = createSlice({
  name: "shopStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncShopStatus.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchAsyncShopStatus.fulfilled, (state, action) => {
        state.openStatus = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchAsyncShopStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default shopStatusSlice.reducer;
