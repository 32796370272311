import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import amplifyConfig from "../../amplifyconfiguration.json";
import Swal from "sweetalert2";

const UserInfo = ({ setGoSteps, selections, dateAndTime, mode }) => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const phonePrefix = "+961";

  const lebanesePhoneNumberRegex = /^(?:(?:\+|00)961)?(?:0|1)?[0-9]\d{7}$/;

  // Handle input change
  const handleInputChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if (inputName === "phone") {
      setPhone(inputValue);
      const finalContact = phonePrefix + inputValue;
      if (!lebanesePhoneNumberRegex.test(finalContact)) {
        setValidationMessage("Please enter a valid Lebanese phone number.");
      } else {
        setValidationMessage("");
      }
    } else if (inputName === "name") {
      setName(inputValue);
    }
  };

  async function executeMutation(mutation, variables) {
    const response = await fetch(
      "https://xnuogii7vzaurlfmigqbntb724.appsync-api.eu-central-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "da2-36ikssg5zfeodjmyqojukeglvu",
          barberid:
            process.env.REACT_APP_BARBER_ID ||
            "c395003b-42c3-48a4-ba8e-af1cebc623e2",
        },
        body: JSON.stringify({
          query: mutation,
          variables: variables,
        }),
      }
    );

    const responseBody = await response.json();
    return responseBody.data;
  }

  // Handle next step
  const handleNextStep = async () => {
    const finalContact = phonePrefix + phone;
    let data = {
      name: name,
      phone: finalContact,
      ...(mode === "reservation" && {
        reservationDate: dateAndTime.reservationDate,
        reservationTime: dateAndTime.reservationTime,
      }),
    };
    if (!lebanesePhoneNumberRegex.test(finalContact)) {
      setValidationMessage("Please enter a valid Lebanese phone number.");
    } else {
      setValidationMessage("");
      setIsSubmitting(true);
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure with these data?",
        icon: "warning",
        customClass: {
          title: "dark-text",
          content: "dark-text",
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        const input = { input: data, employeeId: selections.employeeId, serviceId: selections.serviceId };
        setIsButtonDisabled(true);
        try {
          if (mode === "reservation") {
            const reservationMutation = `mutation CreateReservation($input: ReservationInput!, $employeeId: String!, $serviceId: String!) {
              createReservation(input: $input, employeeId: $employeeId, serviceId: $serviceId) {
                id
                name
                status
                reservationDate
                reservationTime
              }
            }`;
            const response = await executeMutation(reservationMutation, input);
            console.log("Response:", response);
            if (response?.createReservation?.id !== null) {
              navigate(
                `/reservation-confirmation/${response.createReservation.id}`
              );
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                customClass: {
                  title: "dark-text",
                  content: "dark-text",
                },
                text: "This Employee is serving other customer at this time, please select a different time!",
              });
            }

          } else {
            const waitlistMutation = `mutation CreateWaitlist($input: WaitlistInput!, $employeeId: String!, $serviceId: String!) {
              createWaitlist(input: $input, employeeId: $employeeId, serviceId: $serviceId) {
                id
              }
            }`;
            const response = await executeMutation(waitlistMutation, input);
            if (response.createWaitlist?.id !== null) {
              navigate(`/confirmation/${response.createWaitlist[0]?.id}`);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                customClass: {
                  title: "dark-text",
                  content: "dark-text",
                },
                text: "This Employee is not working right now!",
              });
            }
            console.log("Response:", response);
          }
        } catch (error) {
          console.error("Error in GraphQL operation:", error);
        } finally {
          setIsButtonDisabled(false);
          setIsSubmitting(false);
        }
      }
      else {
        setIsButtonDisabled(false);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <div className="row justify-content-center mx-1">
        <div className="custom-border col-12 col-md-10 col-lg-6 waitlist-box">
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(2)}
                className=" gj-cursor-pointer back-btn fs-1 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center">
              <h2 className=" display-6 fw-bold ">Information</h2>
            </div>
            <div className="col-2 text-start"></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 my-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter your name
              </label>
              <input
                type="text"
                className="form-control form-control-lg"

                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-10 my-3">
              <label htmlFor="exampleInputEmail1" className="form-label mb-0">
                Enter Your Phone Number
              </label>
            </div>
            <div className="col-10 mb-3 ">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  {phonePrefix}
                </span>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              {validationMessage && (
                <p className="text-danger">{validationMessage}</p>
              )}
            </div>
            <div className="col-8 col-lg-6 mx-auto">
              {
                isSubmitting ? <><button
                  onClick={handleNextStep}
                  disabled
                  className="btn-main mx-auto mt-5 w-100 px-0"
                >
                  Submitting...
                </button></> : <><button
                  onClick={handleNextStep}
                  className="btn-main bg-primary mx-auto mt-5 w-100 px-0"
                >
                  Submit
                </button></>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
